import axios from "axios";

const subscriptionsService = {
  getSubscriptionsData: async (token) => {
    try {
      const response = await axios.get("/api/sso/subscriptions/all");
      return response.data; // Assuming the API returns user data
    } catch (error) {
      // localStorage.removeItem("auth");
      throw new Error(error.response.data.message);
    }
  },

  downloadInvoice: async (transition, app) => {
    try {
      console.log(transition);
      const response = await axios({
        url: `/api/download-invoice/` + app, // The URL to your Laravel API
        method: "POST",
        responseType: "blob", // Important for handling the PDF binary data
        data: {
          id: transition.id,
        },
      });

      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `invoice-${transition.hid}.pdf`);
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the temporary anchor element
      link.parentNode.removeChild(link);

      return true;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  // Other methods like changePassword, deleteUser, etc. can be added here
};

export default subscriptionsService;
