import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import "animate.css";
import "../style.css";

// Import SVG images
import TransferProduct from "../../../images/ProductsLogo/DarkBg/Transfer.svg";
import Calender from "../../../images/ProductsLogo/DarkBg/Calender.svg";
import Form from "../../../images/ProductsLogo/DarkBg/Form.svg";
import WorkFlow from "../../../images/ProductsLogo/DarkBg/Workflow.svg";
import SupportTick from "../../../images/ProductsLogo/DarkBg/Support Tick.svg";
import HRM from "../../../images/ProductsLogo/DarkBg/HRM.svg";
import Signature from "../../../images/ProductsLogo/DarkBg/signature.svg";
import Accounting from "../../../images/ProductsLogo/DarkBg/Accounting.svg";
import Projects from "../../../images/ProductsLogo/DarkBg/Projects.svg";
import Forms from "../../../images/ProductsLogo/DarkBg/Forms.svg";
import Inventory from "../../../images/ProductsLogo/DarkBg/inventory.svg";
import VideoConference from "../../../images/ProductsLogo/DarkBg/video conference.svg";
import { appData } from "../../../config";

function MainEntry() {
  useEffect(() => {
    document.title = "Streamline Your Workflow, Automate Your Success.";
  }, []);

  // Array of SVGs
  const products = [
    { image: TransferProduct, app: appData[0], avaliable: true },
    { image: Calender, app: appData[1] , avaliable: true},
    { image: Form, app: appData[2] },
    { image: WorkFlow, app: appData[3] },
    { image: SupportTick, app: appData[4] },
    { image: HRM, app: appData[5] },
    { image: Signature, app: appData[6] },
    { image: Accounting, app: appData[7] },
    { image: Projects, app: appData[8] },
    { image: Forms, app: appData[9] },
    { image: Inventory, app: appData[10] },
    { image: VideoConference, app: appData[11] },
  ];

  return (
    <div className="flex justify-center items-center h-full px-4 py-5 bg-[#121212] animate__fadeIn animate__animated animate__faster">
      <div className="flex flex-col justify-between w-11/12 m-auto md:flex-row md:items-center element">
        <div className="text-white w-full md:w-3/5 md:pb-20 pb-10  scrollable-content">
          <h1 className="xl:text-3xl lg:text-2xl md:text-xl text-2xl mb-4 font-[500]">
            {" "}
            Streamline Your Workflow, Automate Your Success.
          </h1>
          <p className="mb-5 xl:text-lg lg:text-base font-extralight">
            Grand Automation is the hub of cutting-edge solutions designed to
            transform your business operations. Each of our products is crafted
            to seamlessly automate and optimize the processes that drive your
            daily work, empowering you to achieve more with less effort.
            Experience the synergy of innovation and convenience with Grand
            Automation, where every tool is a step towards smarter, faster
            business management.
          </p>
          <Link to="/register">
            <Button variant="outlined" color="inherit">
              SIGN UP
            </Button>
          </Link>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-3 auto-cols-auto md:gap-x-3 gap-y-1 animate__fadeIn animate__animated animate__faster ">
          {/* Mapping over the SVG images array */}
          {products.map((product, index) => (
            <a
              key={index}
              href={product?.avaliable ? product?.app?.url : "#"} // Prevent navigation for unavailable products
              target="_blank"
              onClick={product?.avaliable ? null : (e) => e.preventDefault()}
            >
              <img
                key={index}
                className={
                  product?.avaliable
                    ? "xl:w-44 lg:w-40 md:w-36 w-32 cursor-pointer"
                    : "xl:w-44 lg:w-40 md:w-36 w-32 opacity-50 cursor-default"
                }
                src={product.image}
                alt=""
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MainEntry;
