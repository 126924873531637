import React, { useEffect, useState } from "react";
import NotFoundIcon from "../../../Icons/notFound-icon.svg";

// Import products images
import TransferProduct from "../../../images/ProductsLogo/DarkBg/Transfer.svg";
import Calender from "../../../images/ProductsLogo/DarkBg/Calender.svg";
import Form from "../../../images/ProductsLogo/DarkBg/Form.svg";
import WorkFlow from "../../../images/ProductsLogo/DarkBg/Workflow.svg";
import SupportTick from "../../../images/ProductsLogo/DarkBg/Support Tick.svg";
import HRM from "../../../images/ProductsLogo/DarkBg/HRM.svg";
import Signature from "../../../images/ProductsLogo/DarkBg/signature.svg";
import Accounting from "../../../images/ProductsLogo/DarkBg/Accounting.svg";
import Projects from "../../../images/ProductsLogo/DarkBg/Projects.svg";
import Forms from "../../../images/ProductsLogo/DarkBg/Forms.svg";
import Inventory from "../../../images/ProductsLogo/DarkBg/inventory.svg";
import VideoConference from "../../../images/ProductsLogo/DarkBg/video conference.svg";
import subscriptionsService from "../../../Services/subscriptionsService";
import { ThreeDots } from "react-loader-spinner";
import { appData } from "../../../config";
import toast from "react-hot-toast";
import NavigateLoader from "../../../Components/Common/NavigateLoader";
import TransferProductV2 from "../../../images/productsBorderless/transfer.svg";
import CalenderV2 from "../../../images/productsBorderless/calender.svg";

function SubscriptionTab() {
  const products = [
    {
      name: "Transfer",
      img: TransferProductV2,
      url: "https://app.grandtransfer.io/",
    },
    {
      name: "GrandCalender",
      img: CalenderV2,
      url: "https://app-stage.grandcalendar.io/",
    },
  ];

  const productsAll = [
    { image: TransferProduct, app: appData[0], avaliable: true },
    { image: Calender, app: appData[1], avaliable: true },
    { image: Form, app: appData[2] },
    { image: WorkFlow, app: appData[3] },
    { image: SupportTick, app: appData[4] },
    { image: HRM, app: appData[5] },
    { image: Signature, app: appData[6] },
    { image: Accounting, app: appData[7] },
    { image: Projects, app: appData[8] },
    { image: Forms, app: appData[9] },
    { image: Inventory, app: appData[10] },
    { image: VideoConference, app: appData[11] },
  ];

  const [subscribed, setSubscribed] = useState(false);
  document.title =
    "Subscription | Streamline Your Workflow, Automate Your Success.";
  console.log(subscribed);

  const [show, setShow] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [textLoader, setTextLoader] = useState("loading...");

  const handleProductClick = async (app) => {
    // open app in new tab with token from local storage

    setShow(true);
    setSelectedApp(app);
    console.log(app);

    // get token from local storage
    const User = JSON.parse(localStorage.getItem("auth"));
    if (!User) {
      toast.error("You are not logged in");
      setShow(false);
      return;
    }

    let redirectUrl =
      app.url + "checkLogin?token=" + User.token + "&redirect=true&path=";

    // setTextLoader('Redirecting to ' + app['shortName'] + ' App...') after 2 seconds

    setTimeout(() => {
      setTextLoader("Redirecting to " + app["name"] + " App...");
      window.location.href = redirectUrl;
      // if (newTab) {
      //     newTab.focus();
      // } else {
      //     window.location.href = redirectUrl; // Fallback if new tab was blocked
      // }
      setShow(false);
    }, 1000);
  };

  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await subscriptionsService.getSubscriptionsData();

        console.log(response);
        // filer show only if is_active_subscription is true
        const activeSubscriptionsGT =
          response?.gt_subscriptions?.current_subscription ?? [];

        const activeSubscriptionsGC =
          response?.gc_subscriptions?.current_subscription ?? [];
        const activeSubscriptions = [
          ...activeSubscriptionsGT,
          ...activeSubscriptionsGC,
        ];

        console.log(activeSubscriptions);

        setSubscriptions(activeSubscriptions ?? []);
        if (activeSubscriptions?.length > 0) {
          setSubscribed(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.log(error);
      }
    };
    fetchSubscriptions();
  }, []);

  return (
    <div className="">
      {show && <NavigateLoader text={textLoader} />}

      <div className="bg-[#121212] rounded-md border border-[#2a2a2a] overflow-hidden w-full">
        <div className=" bg-[#121212] p-5">
          <h6>Subscriptions</h6>
        </div>
        {loading ? (
          <div
            className={`flex justify-center items-center z-50 transition-opacity
                   }`}
          >
            <ThreeDots color="white" height={100} width={100} />
          </div>
        ) : (
          <div>
            {subscribed ? (
              <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-7 p-7 bg-[#0d0d0d]">
                {subscriptions.map((app, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="bg-[#121212] flex flex-col items-center border border-[#2a2a2a] rounded-lg overflow-hidden pt-3 xl:text-base sm:text-sm text-xs "
                      >
                        <img
                          src={
                            app.app == "GT"
                              ? products[0]?.img
                              : products[1]?.img
                          }
                          alt=""
                          className="md:w-40 sm:w-32 w-24"
                        />
                        <h6 className="xl:text-lg sm:text-base text-sm font-semibold px-3">
                          {app.is_free
                            ? app.name
                            : app?.plan_data["name"] +
                              " / " +
                              app?.subscription_period}
                        </h6>
                        <p className="pb-5 mt-2 text-[#888888]">
                          {app.subscription_date_range}
                        </p>
                        <div className="bg-[#0d0d0d] w-full text-center p-5">
                          <button
                            onClick={() => {
                              // open in new tab
                              const url =
                                app.app == "GT"
                                  ? products[0]?.url
                                  : products[1]?.url;
                              window.open(url, "_blank");
                            }}
                            className="bg-[#323232] p-2 rounded-lg font-semibold w-full hover:bg-white hover:text-black transition-all duration-300 xl:text-[13px] text-xs"
                          >
                            Manage Subscription
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            ) : (
              <div className="bg-[#0d0d0d]">
                <div className="flex justify-center w-full pt-7 mb-2 px-2">
                  <img
                    src={NotFoundIcon}
                    className="me-3 sm:w-6 w-5 sm:block hidden"
                    alt=""
                  />
                  <p className="md:text-xl sm:text-base text-sm text-center text-white">
                    You are not subscribed to any of our products
                  </p>
                </div>
                <p className="text-center md:text-base sm:text-sm text-xs pb-7">
                  Explore our products
                </p>
                <div className="grid md:grid-cols-6 sm:grid-cols-4 grid-cols-2 p-7 pt-0">
                  {productsAll.map((product, index) => {
                    return (
                      <a
                        key={index}
                        onClick={() => {
                          if (product?.avaliable)
                            handleProductClick(product.app);
                        }}
                        className={
                          product?.avaliable
                            ? "cursor-pointer lg:w-40 md:w-32 sm:w-28 w-32"
                            : "lg:w-40 md:w-32 sm:w-28 w-32 opacity-50 cursor-default"
                        }
                      >
                        <img
                          src={product.image}
                          className={index === 0 ? "" : ""}
                          alt={product.name}
                          width={150}
                        />
                      </a>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SubscriptionTab;
